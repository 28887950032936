import { Dropdown as AriesDropdown, Greyscale } from 'glints-aries';
import styled from 'styled-components';

import { ProfilePicture as GlintsProfilePicture } from 'src/components/GlintsPicture';

export const UserMenuContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

export const UserSettingSection = styled.div`
  position: relative;
`;

export const Dropdown = styled(AriesDropdown)`
  ul {
    border-radius: 4px;
  }

  li:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  li:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const DropdownWrapper = styled.div`
  align-items: center;
  font-weight: 500;
  color: ${Greyscale.black};

  svg {
    margin-right: 15px;
  }
`;

export const NameHolder = styled.strong`
  display: block;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`;

export const ProfilePicture = styled(GlintsProfilePicture)`
  border-radius: 50%;
  margin-right: 10px;
`;
